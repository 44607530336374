import React from 'react'
import "./TicketMessage.css"
import RS from "../svg/RS"
import Clock from "../svg/Clock"
import With from "../svg/withdrawl"
import Arrowwithdrawl from "../svg/Arrowwithdrawl"
import Cash from "../svg/cash"
import CashType from "../svg/cashtype"
import User from "../svg/user"
import Id from "../svg/ID"
import Phone from "../svg/phone"
import Operation from "../svg/operation"
import Wallet from "../svg/wallet"
import Company from "../svg/company"
// import calander from "../svg/calander.svg"
import Marker from "../svg/marker"
// import link from "../svg/link.svg"
import Time from "../svg/time"
// import date from "date-and-time"
import DateDiff from 'date-diff'
import { textMarshal } from 'text-marshal'
// import { useEffect } from 'react'
import { useState } from 'react'
import Spinner from '../../../../Spinner'
import Waicon from "./waicon"
import { useDispatch, useSelector } from 'react-redux'
import Hint from './hint'
import Tether from '../svg/tether'
import Deposit from '../svg/deposit'

function TicketMessage(props) {
  const ticket_loading = useSelector((state) => state.whatsapp.ticket_loading)
  const ticket_loading_type = useSelector((state) => state.whatsapp.ticket_loading_type)
  // var currencies = require('country-data').currencies;
  const dispatch = useDispatch()
  const [diff, setDiff] = useState(new DateDiff(new Date(), new Date(props.created_at)))
  const getPhone = (num) => {
    let d = textMarshal({
      input: num,
      template: `+ xxx xxx xxxxxxxxx`,

    });
    return (d.marshaltext)
  }
  // var countries = require('country-data').countries;
  var lookup = require('country-data').lookup;
  // let alternative_countries = require('countries-list').countries
  const getCountry = (val) => {
    var data = lookup.countries({ name: val })[0];
    if (data) {
      return data
    }
  }
  const getWhatsappChatId = (id, type, ticket) => {
    dispatch({
      type: "GET-WA-CHAT-TICKET", payload: {
        id: props.id, number_type: type, ticket: ticket
      }
    })
  }
  return (
    <div className={`${props.is_hint ? "TicketHintMessage " : "TicketMessage "} ${props.isMessage ? 'isMessage' : ""}`}>
      {props.is_hint && <Hint />}
      <div className='rs-icon'>
        <RS />
      </div>
      <div className='withdrawl-element-container' >
        <div className='withdrawl-icon'>
          {props.message?.type === "deposit" ? <Deposit /> : <With />}
        </div>
        <div className='arrow-withdrawl-icon'>
          <Arrowwithdrawl />
        </div>
        <div className='ref-number'>
          {props.message?.request_number}
        </div>
      </div>
      {props.message?.client && props.message?.client !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          {(props.message?.arabic_name === "False" || !props.message?.arabic_name) && <User />}
        </div>
        <div className='ticket-icon-name'>
          {props.message?.client}
        </div>
      </div>}
      {props.message?.name && props.message?.name !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          {(props.message?.arabic_name === "False" || !props.message?.arabic_name) && <User />}
        </div>
        <div className='ticket-icon-name'>
          {props.message?.name}
        </div>
      </div>}
      {props.message?.arabic_name && props.message?.arabic_name !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          <User />
        </div>
        <div className='ticket-icon-name'>
          {props.message?.arabic_name}
        </div>
      </div>}
      {props.message?.account_number &&
        <div className='ticket-item'>
          <div className='ticket-icon'>
            <Id />
          </div>
          <div className='ticket-icon-name id-ticket'>
            {props.message?.account_number}
          </div>
        </div>}
      {props.message?.request_number &&
        <div className='ticket-item'>
          <div className='ticket-icon'>
            <Id />
          </div>
          <div className='ticket-icon-name id-ticket'>
            {props.message?.request_number}
          </div>
        </div>}

      {props.message?.operatingUnit && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Company />
        </div>
        <div className='ticket-icon-name'>
          {props.message?.operatingUnit}
        </div>
      </div>}
      {props.message?.company && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Company />
        </div>
        <div className='ticket-icon-name'>
          {props.message?.company}
        </div>
      </div>}
      {(props.message?.payment_type && (props.message?.payment_type !== "False")) &&
        <div className={`ticket-item`}>
          <div className='ticket-icon'>
            <CashType />
          </div>
          <div className='ticket-icon-name'>
            {props.message?.payment_type}
          </div>
        </div>}
      {props.message?.amount && props.message?.amount !== "False" &&
        <div className='ticket-item bold-'>
          <div className='ticket-icon'>
            <Cash />
          </div>
          <div className='ticket-icon-name amount-ticket'>
            {props.message?.amount_to_display ? props.message?.amount_to_display : parseFloat(props.message?.amount).toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {" "}
            {props.message?.currency !== "False" &&
              lookup.currencies({ code: props.message?.currency }).length > 0 &&
              lookup.currencies({ code: props.message?.currency })[0] &&
              lookup.currencies({ code: props.message?.currency })[0].symbol}
            {props.message?.localCurrencyAmountToDisplay && (parseFloat(props.message?.localCurrencyAmountToDisplay) !== 0) && props.message?.localCurrency ? ` / ${props.message?.localCurrencyAmountToDisplay}
            ${props.message?.localCurrency === "EGP" ? "EGP" : (props.message?.localCurrency !== "False" &&
                lookup.currencies({ code: props.message?.localCurrency }).length > 0 &&
                lookup.currencies({ code: props.message?.localCurrency })[0] &&
                lookup.currencies({ code: props.message?.localCurrency })[0].symbol ?
                lookup.currencies({ code: props.message?.localCurrency })[0].symbol : props.message?.localCurrency)}` : ` / ${parseFloat(props.message?.localCurrencyAmount).toFixed(2)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            ${props.message?.localCurrency === "EGP" ? "EGP" : (props.message?.localCurrency !== "False" &&
              lookup.currencies({ code: props.message?.localCurrency }).length > 0 &&
              lookup.currencies({ code: props.message?.localCurrency })[0] &&
              lookup.currencies({ code: props.message?.localCurrency })[0].symbol ?
              lookup.currencies({ code: props.message?.localCurrency })[0].symbol : props.message?.localCurrency)}`}
          </div>
        </div>}
      {props.message?.destinationCountry && props.message?.destinationCountry !== "False" &&
        <div className='ticket-item'>
          <div className='ticket-icon'>
            {((props.message?.destinationCountry === "Crypto Currency") || (props.message?.destinationCity === "Crypto Currency") || (props.message?.destinationCountry === "USDT") || (props.message?.destinationCity === "USDT")) ? <Tether /> : getCountry(props.message?.destinationCountry)?.emoji}
          </div>
          <div className='ticket-icon-name'>
            {getCountry(props.message?.destinationCountry)?.name || props.message?.destinationCountry}, {props.message?.destinationCity}
          </div>
        </div>}


      {props.message?.commissionType && props.message?.commissionType !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Operation client={props.message?.commissionType?.toLowerCase() === "client"} />
        </div>
        <div className={`ticket-icon-name ${props.message?.commissionType?.toLowerCase() === "client" ? "payment-type-red-bold" : ""}`}>
          {props.message?.commissionType}
        </div>
      </div>}
      {props.message?.maskedPhoneNumber && props.message?.maskedPhoneNumber !== "False" && props.message?.maskedPhoneNumber !== "+False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Phone />
        </div>
        <div className='ticket-icon-name phone-ticket'>
          {getPhone(props.message?.maskedPhoneNumber)}
        </div>
        {props.is_client_phone_has_whatsapp === 1 && <div onClick={() => getWhatsappChatId(props.id, "phone", props.ticket)} className='whatsapp-icon-user'>
          {props.is_hint ? <></> : (((ticket_loading === props.id) && (ticket_loading_type === "phone")) ?
            <Spinner /> :
            <Waicon />)
          }
        </div>}
      </div>}
      {props.message?.masked_mobile !== null && (props.message?.masked_mobile !== props.message?.maskedPhoneNumber && props.message?.masked_mobile && props.message?.masked_mobile !== "False" && props.message?.masked_mobile !== "+False") && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Phone />
        </div>
        <div className='ticket-icon-name phone-ticket'>
          {getPhone(props.message?.masked_mobile)}
        </div>
        {props.is_client_mobile_has_whatsapp === 1 && <div onClick={() => getWhatsappChatId(props.id, "mobile", props.ticket)} className='whatsapp-icon-user'>
          {props.is_hint ? <></> : (((ticket_loading === props.id) && (ticket_loading_type === "mobile")) ?
            <Spinner /> :
            <Waicon />)
          }
        </div>}
      </div>}
      {props.message?.transferDate && props.message?.transferDate !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Time />
        </div>
        <div className='ticket-icon-date'>
          {props.message?.transferDate}
        </div>
      </div>}
      {props.message?.comment && props.message?.comment !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Wallet />
        </div>
        <div className='ticket-icon-name wallet'>
          {props.message?.comment}
        </div>
      </div>}
      {props.message?.address && props.message?.address !== "False" && <div className='ticket-item'>
        <div className='ticket-icon'>
          <Marker />
        </div>
        <div className='ticket-icon-name'>
          {props.message?.address}
        </div>
      </div>}
      {props.is_hint && <span style={{
        bottom: "10px", right: "23px", position: "absolute", fontSize: "0.7rem",
        fontWeight: "500"
      }}>
        {props.messageTime}
      </span>}
    </div>
  )
}

export default TicketMessage